import React from 'react'
import PropTypes from 'prop-types'

const ContentText = ({ text }) => (
  <div dangerouslySetInnerHTML={{ __html: text.html }} />
)

export default ContentText

ContentText.propTypes = {
  text: PropTypes.object.isRequired,
}




import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Link } from "gatsby"
import { ContentText, ContentImage, ContentVideo } from '../components/content'
import Img from 'gatsby-image'

const News = ({
    data: {
      news: {
        first_publication_date,
        data: {
          title,
          hero_image,
          body,
          teasertext,
        }
      }
    },
}) => {

  // I extract the embed URL from the iframe returned by Prismic
  // because the iframe returns fixed dimensions and I want a bit more customization
  const extractEmbedURL = (prismicEmbedVideoHTML) => {
    // let's solve this with good old regular expressions:
    // We look for src="XXX", where XXX (videosrc) is what we need:
    const regexp = /src="(?<videosrc>[^"]+)"/
    const match = regexp.exec(prismicEmbedVideoHTML)
    return match.groups.videosrc
  }

  const renderBody = (slice) => {
    console.log(slice);
    switch(slice.slice_type) {
      case 'text':
        return <ContentText text={slice.primary.text} />;
      case 'image':
        return <ContentImage image={slice.primary.image} />;
      case 'video':
        // We could take the iframe and use it directly, like shown here: https://prismic.io/docs/technologies/rendering-the-embed-field-reactjs
        // return <div dangerouslySetInnerHTML={{ __html: slice.primary.video.html }}/>;
        // but instead we take the embed url and customize the iframe
        return <ContentVideo videoSrcURL={extractEmbedURL(slice.primary.video.html)}/>;
      default:
        return null;
    }
  }

  return (
    <Layout>
      <div className="max-w-screen-lg mx-auto px-4 relative z-20 mt-20">
        <h1 className="font-display font-bold text-4xl lg:text-5xl lg:leading-tight mt-0 mb-1 leading-tight">{ title.text }</h1>
        <p className="mb-8">{ first_publication_date }</p>
        { hero_image ?
          <Img fluid={hero_image.fluid} alt={hero_image.alt} className="rounded md:rounded-r-none md:rounded-t" />
          : null
        }
        <div className="prose-xl">
          {body.map((slice) => {
              return renderBody(slice)
          })}
        </div>
        <Link to="/news" className="rounded-md mt-12 bg-gradient-to-tr from-secondary-base to-secondary-700 font-bold py-2 px-4 text-center justify-center flex items-center transform hover:scale-99 transition">Back to all News</Link>
      </div>
    </Layout>
  )
}

export default News

News.propTypes = {
  title: PropTypes.object.isRequired,
  hero_image: PropTypes.object,
  body: PropTypes.object.isRequired,
  teasertext: PropTypes.object,
}

export const query = graphql`
  query($uid: String!) {
    news: prismicNews(uid: {eq: $uid}) {
      uid
      first_publication_date(formatString: "DD.MM.y")
      data {
        title {
          text
        }
        teasertext {
          text
        }
        hero_image {
          alt
          fluid(maxWidth: 1248) {
            ...GatsbyPrismicImageFluid
          }
        }
        body {
          ... on PrismicNewsDataBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicNewsDataBodyImage {
              id
              slice_type
              primary {
                image {
                  alt
                  fluid(maxWidth: 1248) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
            }
            ... on PrismicNewsDataBodyVideo {
              id
              slice_type
              primary {
                video {
                  html
                  title
                }
              }
            }
        }
      }
    }
  }
`



import React from 'react'
import PropTypes from 'prop-types'


const ContentVideo = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="embedded-video">
    <iframe className="embedded-video-iframe"
      src={videoSrcURL}
      width="560"
      height="349"
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)

export default ContentVideo

ContentVideo.propTypes = {
  videoSrcURL: PropTypes.string.isRequired,
}
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'


const ContentImage = ({ image }) => (
  <Img fluid={image.fluid} alt={image.alt} />
)

export default ContentImage

ContentImage.propTypes = {
    image: PropTypes.object.isRequired,
}